import React, { FC, memo, useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { CategoryPickerItem, TCategoryPickerItem } from '../CategoryPickerItem/CategoryPickerItem';
import { Carousel } from '~/shared/components/Carousel/Carousel';
import Grid from '~/shared/components/Grid/Grid';
import Chevron from '~/icons/chevron.svg';
import { LinkProps } from '~/shared/components/Link/Link';
import { ImageProps } from '~/shared/components';
import { Gutter } from '~/shared/components/Gutter/Gutter';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';

export type TCategory = {
    icon: ImageProps;
    link: LinkProps;
};

export type TCategorySlider = {
    categories?: TCategoryPickerItem[];
    onClick?: (e: React.SyntheticEvent, value: string) => void;
};

const Icon = styled(Chevron)``;

const CarouselButton = styled(StyledResetButton)<{ position: 'left' | 'right' }>(
    ({ theme, position }) => ({
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 20,
        padding: '7px 14px',
        position: 'relative',
        '&:disabled': {
            opacity: 0,
            zIndex: -1,
        },
        [`${Icon}`]: {
            transform: `rotate(${position === 'left' ? '180deg' : '0deg'})`,
        },
        zIndex: 2,
        boxShadow: '0 0px 10px rgba(0,0,0,0.3)',
    }),
);

const CarouselWrapper = styled.div({
    clipPath: 'inset(0 0px round 25px 25px 25px 25px)',
    display: 'grid',
});

const SliderWrapper = styled.div({
    display: 'grid',
    gridArea: '1/1',
    '& > *': {
        gridArea: '1/1',
    },
});

export const CategorySlider = ({ categories, onClick }: TCategorySlider) => {
    const nextRef = useRef<HTMLButtonElement | null>(null);
    const prevRef = useRef<HTMLButtonElement | null>(null);

    const categoryItems = useMemo(
        () =>
            categories?.map((category, index) => (
                <CategoryPickerItem
                    key={`${category.link?.url ?? category?.link.title}-${index}`}
                    onClick={onClick}
                    {...category}
                    isFirst={index === 0}
                />
            )),
        [categories],
    );

    return (
        <SliderWrapper>
            <CarouselWrapper>
                <Carousel
                    options={{ spaceBetween: 8 }}
                    items={categoryItems}
                    nextRef={nextRef}
                    prevRef={prevRef}
                />
            </CarouselWrapper>

            <Grid flow="column" columns="auto 1fr auto">
                <CarouselButton aria-label="Previous" position="left" ref={prevRef}>
                    <Icon />
                </CarouselButton>

                <div />

                <CarouselButton aria-label="Next" position="right" ref={nextRef}>
                    <Icon />
                </CarouselButton>
            </Grid>
        </SliderWrapper>
    );
};

interface TCategoryMenu {
    categories?: TCategoryPickerItem[];
}

const CategoryMenu: FC<TCategoryMenu> = ({ categories }) => {
    const { data: frame } = useFrame();
    const { filterEvent } = useEvents(frame);

    const onCategoryClick = useCallback(
        (_event: React.SyntheticEvent, value: string) => filterEvent('ProductType', value),
        [filterEvent],
    );

    return (
        <ModuleContainer spacingBottom="xs" spacingTop="xs">
            <Gutter gutterSize="xl" gutterOnMobile={false}>
                <CategorySlider categories={categories} onClick={onCategoryClick} />
            </Gutter>
        </ModuleContainer>
    );
};

export default memo(CategoryMenu);
